<template>
  <div id="app" class="app-container">
    <!-- First background section -->
    <div class="background">
      <nav class="navbar">
        <!-- Burger slide button on the left -->
        <div class="navbar-left">
          <Slide class="BurgerSlide">
            <div class="burger-menu-content" :width="auto" :margin-left="0" :text-align="left">
              <router-link class="burgerlink" to="/">Home</router-link>
              <router-link class="burgerlink" to="/3Dprinting">{{ $t('3D-printen-router') }}</router-link>
              <router-link class="burgerlink" to="/Advies">{{ $t('Advies-router') }}</router-link>
              <router-link class="burgerlink" to="/Cursussen">{{ $t('Cursussen-router') }}</router-link>
              <router-link class="burgerlink" to="/Prints">{{ $t('Prints-router') }}</router-link>
              <router-link class="burgerlink" to="/Zwangerschap">{{ $t('Zwangerschap-scans-en-prints-router') }}</router-link>
              <router-link class="burgerlink" to="/Bustes">{{ $t('Bustes-router') }}</router-link>
              <router-link class="burgerlink" to="/3Dscannen">{{ $t('3D-Scannen-router') }}</router-link>
              <router-link class="burgerlink" to="/Assembleren">{{ $t('Assembleren-3D-printers-router') }}</router-link>
              <router-link class="burgerlink" to="/Onderhoud">{{ $t('Onderhoud-3D-printers-router') }}</router-link>
              <router-link class="burgerlink" to="/ContactPage">{{ $t('Contact-pagina-router') }}</router-link>
              <!-- Language buttons in the burger menu (for mobile) -->
              <div v-if="isMobile" class="mobile-language-buttons">
                <button @click="changeLanguage('nl')">
                  <img :src="require('@/assets/netherlands.png')" alt="Nederlands" class="flag-icon">
                </button>
                <button @click="changeLanguage('en')">
                  <img :src="require('@/assets/united-kingdom.png')" alt="English" class="flag-icon">
                </button>
                <button @click="changeLanguage('de')">
                  <img :src="require('@/assets/germany.png')" alt="Deutsch" class="flag-icon">
                </button>
              </div>
            </div>
          </Slide>
        </div>

        <!-- Logo in the top-right -->
        <div class="navbar-logo">
          <router-link to="/"><img class="logo" src="./assets/Wesley.png" alt="logo"></router-link>
        </div>

        <!-- Language buttons on the right (for larger screens) -->
        <div v-if="!isMobile" class="language-container">
          <div class="language-buttons">
            <button @click="changeLanguage('nl')">
              <img :src="require('@/assets/netherlands.png')" alt="Nederlands" class="flag-icon">
            </button>
            <button @click="changeLanguage('en')">
              <img :src="require('@/assets/united-kingdom.png')" alt="English" class="flag-icon">
            </button>
            <button @click="changeLanguage('de')">
              <img :src="require('@/assets/germany.png')" alt="Deutsch" class="flag-icon">
            </button>
          </div>
        </div>
      </nav>
      
      <!-- Main content area -->
      <router-view />
    </div>

    <!-- Footer section -->
    <footer class="footer">
      <div class="footer-content">
        <!-- Social Media Links -->
        <div class="row">
          <a href="#"><i class="fa fa-facebook"></i></a>
          <font-awesome-icon :icon="['fab', 'instagram']" beat />
          <a href="https://www.instagram.com/everything3d.nl?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i class="fa-brands fa-instagram fa-beat"></i></a>
          <a href="#"><i class="fa fa-youtube"></i></a>
          <a href="#"><i class="fa fa-twitter"></i></a>
        </div>
        
        <!-- Navigation Links -->
        <div class="row">
          <ul>
            <li><router-link class="burgerlink" to="/ContactPage">{{ $t('Contact-pagina-router') }}</router-link></li>
          </ul>
        </div>

        <!-- Copyright Information -->
        <div class="row">
         - All rights reserved || Everything-3D -
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Slide } from 'vue3-burger-menu';

export default {
  name: 'App',
  components: {
    Slide,
  },
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.handleResize(); // Check on initial load
    window.addEventListener('resize', this.handleResize); // Listen for window resize
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
/* Ensure full page height for footer to be sticky */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.background {
  background: url('/src/assets/back1.jpg') no-repeat center center fixed;
  background-size: cover;
  flex: 1; /* Let this grow and take available space */
}

.navbar {
  background-color: rgb(75, 73, 70);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.navbar-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-logo {
  position: absolute; /* Absolute positioning for centered logo on larger screens */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.language-container {
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f0f0f0;
}

.language-buttons {
  display: flex;
  gap: 10px;
}

.flag-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.logo {
  max-height: 40px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ffffff;
  scale: 150%;
}

/* Logo hover effect */
.logo {
  max-height: 40px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
}

.logo:hover {
  transform: scale(1.1); /* Slightly enlarge the logo on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow to the logo on hover */
}

/* Burger button styling with white padding */
.bm-burger-button {
  padding: 10px;
  background-color: white; /* White background */
  border-radius: 8px;
  border: 2px solid #ffffff; /* Match language button style */
  transition: all 0.3s ease;
}

.bm-burger-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.burgerlink {
  color: #ffffff;
  text-decoration: none;
  transition: transform 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  font-size: 18px;
}

.burgerlink:hover {
  font-size: 20px;
}

.burgerlink:active {
  font-size: 22px;
  transform: translateX(10px);
}

.router-link-active {
  color: #d3d3d3;
}

.BurgerSlide {
  text-decoration-color: rgb(255, 255, 255);
  text-align: left;
}

.burger-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f1dd00;
}

.bm-item-list {
  color: #f1dd00;
  margin-left: 0;
  font-size: 20px;
  background-color: rgb(255, 255, 255);
}

/* Footer styling */
.footer {
  background: #000;
  padding: 20px 0px;
  font-family: 'Play', sans-serif;
  text-align: center;
  width: 100%;
  position: static;
  bottom: 0;
}

.footer .row {
  width: 100%;
  margin: 1% 0%;
  padding: 0.6% 0%;
  color: gray;
  font-size: 0.8em;
}

.footer .row a {
  text-decoration: none;
  color: gray;
  transition: 0.5s;
}

.footer .row a:hover {
  color: #fff;
}

.footer .row ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.footer .row ul li {
  display: inline-block;
  padding: 0px 20px;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
  transition: 0.5s;
}

.footer .row a i:hover {
  color: #fff;
}

</style>
