import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../components/HomeView.vue'
import NotFound from '@/components/NotFound.vue';

const routes = [
  {
    path: '//',
    name: 'HomePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/HomePage.vue')
    
  },
  {
    path: '/3dprinting',
    name: '3D Printen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/3Dprinting.vue')
    
  },
  {
    path: '/AboutPage',
    name: 'AboutPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/AboutPage.vue')
    
  },
  {
    path: '/ContactPage',
    name: 'ContactPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ContactPage.vue')
    
  },
  {
    path: '/Advies',
    name: 'Advies',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/AdviesView.vue')
    
  },
  {
    path: '/Cursussen',
    name: 'Cursussen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/CursussenView.vue')
    
  },
  {
    path: '/Prints',
    name: 'Prints',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/PrintsView.vue')
    
  },
  {
    path: '/Zwangerschap',
    name: 'Zwangerschap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ZwangerschapView.vue')
    
  },
  {
    path: '/Bustes',
    name: 'Bustes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/BustesView.vue')
    
  },
  {
    path: '/3Dscannen',
    name: '3D-Scannen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/3Dscannen.vue')
    
  },
  {
    path: '/Assembleren',
    name: 'Assembleren 3D Printers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/AssemblerenView.vue')
    
  },
  {
    path: '/Onderhoud',
    name: 'Onderhoud 3D Printers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/OnderhoudView.vue')
    
  },

   // Add other routes here
   {
    path: '/:catchAll(.*)', // Wildcard route to catch undefined paths
    name: 'NotFound',
    component: NotFound,
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
