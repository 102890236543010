<template>
  <div class="not-found-container">
    <!-- First background section -->
    <div class="background">
      <nav class="navbar">
        <!-- Logo in the top-right -->
        <div class="navbar-logo">
          <router-link to="/"><img class="logo" src="C:\ict_jaar_3\eerte project website wissely\eigenwebsite2\src\assets\Wesley.png" alt="logo"></router-link>
        </div>

        <!-- Language buttons on the right (for larger screens) -->
        <div v-if="!isMobile" class="language-container">
          <div class="language-buttons">
            <button @click="changeLanguage('nl')">
              <img :src="require('@/assets/netherlands.png')" alt="Nederlands" class="flag-icon">
            </button>
            <button @click="changeLanguage('en')">
              <img :src="require('@/assets/united-kingdom.png')" alt="English" class="flag-icon">
            </button>
            <button @click="changeLanguage('de')">
              <img :src="require('@/assets/germany.png')" alt="Deutsch" class="flag-icon">
            </button>
          </div>
        </div>
      </nav>

      <!-- 404 Message -->
      <div class="content">
        <h1 class="error-title">404 - Page Not Found</h1>
        <p class="error-message">Sorry, the page you're looking for doesn't exist.</p>
        <router-link class="return-home" to="/">Go Back Home</router-link>
      </div>
    </div>

    <!-- Footer -->
    <footer class="footer">
      <div class="footer-content">
        <div class="row">
          <a href="#"><i class="fa fa-facebook"></i></a>
          <a href="https://www.instagram.com/everything3d.nl?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i class="fa-brands fa-instagram fa-beat"></i></a>
          <a href="#"><i class="fa fa-youtube"></i></a>
          <a href="#"><i class="fa fa-twitter"></i></a>
        </div>

        <div class="row">
          <ul>
            <li><router-link to="/ContactPage">{{ $t('Contact-pagina-router') }}</router-link></li>
          </ul>
        </div>

        <div class="row">
          - All rights reserved || Everything-3D -
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.not-found-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.background {
  background: url('/src/assets/back1.jpg') no-repeat center center fixed;
  background-size: cover;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.navbar {
  background-color: rgb(75, 73, 70);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.navbar-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.language-container {
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f0f0f0;
}

.language-buttons {
  display: flex;
  gap: 10px;
}

.flag-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.logo {
  max-height: 40px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ffffff;
  scale: 150%;
}

.logo:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.content {
  color: #ffffff;
}

.error-title {
  font-size: 48px;
  color: #f1dd00;
}

.error-message {
  font-size: 24px;
  margin: 10px 0;
}

.return-home {
  font-size: 20px;
  padding: 10px 20px;
  background-color: #f1dd00;
  color: #000;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.return-home:hover {
  background-color: #d3b800;
}

/* Footer styling */
.footer {
  background: #000;
  padding: 20px 0px;
  text-align: center;
  width: 100%;
}

.footer .row {
  width: 100%;
  margin: 1% 0%;
  padding: 0.6% 0%;
  color: gray;
  font-size: 0.8em;
}

.footer .row a {
  text-decoration: none;
  color: gray;
  transition: 0.5s;
}

.footer .row a:hover {
  color: #fff;
}

.footer .row ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.footer .row ul li {
  display: inline-block;
  padding: 0px 20px;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
  transition: 0.5s;
}

.footer .row a i:hover {
  color: #fff;
}
</style>
